<template>
  <router-link tag="div" :to="`/detail_project/${content.id}`" class="article">
      <figure class="article__type" :style="`background: url('${content.photo}') center / cover`"></figure>
      <!-- <p class="article__date">{{ getFormattedDate(new Date(content.date)) }}</p> -->
      <p class="article__title">{{ content.title }}</p>
      <p class="article__text" v-trim="Number(trim)">{{ content.subtitle }}</p>
  </router-link>
</template>

<script>
import formatDate from '@/mixins/formatDate'
export default {
  name: 'ArticleCard',
  mixins: [formatDate],
  props: {
    content: {
      type: Object
    },
    trim: {
      type: [Number, String],
      default: 3
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    mediaTypePhoto () {
      return this.card.mediaType.code === 'PHOTO_REPORT'
    }
  }
}
</script>

<style scoped lang="scss">
.article {
  width: 26.56rem;
  height: 23.44rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.38rem;
  &:nth-child(2){ margin: 0 2.19rem; }

  &__type {
    width: 100%;
    height: 14.38rem;

    margin-bottom: 1.25rem;
    border-radius: .5rem;
  }
  &__date{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    margin-bottom: .312rem;
  }

  &__text{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #171717;
    opacity: 0.8;
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .article {
    &__text, &__title {
      width: 100%;
    }
  }
}

</style>
