<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <section class="white-container project">
            <h5>Проекты</h5>
            <tricolor-line />
            <div class="project__tabs">
                <p class="active">Текущие проекты</p>
                 <router-link to="/forum">Форум «Сообщество»</router-link>
                <router-link to="/my-project">Мой проект — моей стране!</router-link>
            </div>
            <div class="project__content">
                <article-card v-for="(item, index) in content" :content="item" trim="4" :key="`item${index}`" />
            </div>
            <!-- <component :is="tabs[isActiveTab]" :content="content[isActiveTab]" /> -->

        </section>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import ArticleCard from '@/components/activity/ArticleCard'

export default {
  name: 'ActivitiesProjects',
  components: {
    BreadCrumb,
    TricolorLine,
    ArticleCard
  },
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Проекты'
        }
      ],
      // pages: [
      //   'news-public',
      //   'information-educated'
      // ]

      content: []
    }
  },
  methods: {
    getPage () {
      console.log('page')
    }
  },
  watch: {
    '$store.getters.getProject' (value) {
      value.map(item => {
        this.content.push({
          date: new Date(item.date).toLocaleDateString('ru'),
          title: item.name,
          subtitle: item.descriptionClear,
          photo: `${this.$store.getters.getUrlApi}${item.previewPhoto}`,
          id: item.id
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('setProject')
    document.title = 'Проекты'
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;

    &__text{
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
        width: 42.81rem;
        margin: 2rem 0 2.38rem 0;
    }
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}

.project {
  &__tabs{
    display: flex;
    border-radius: .125rem;
    border-bottom: .06rem solid #D5D6D9;
    margin: 2rem 0;

    p, a{
      font-size: .75rem;
      line-height: 1.125rem;
      color: #003E78;
      opacity: 0.6;
      margin-right: 1.25rem;
      padding-bottom: 1.375rem;
      cursor: pointer;
      transition: font-size .2s;
    }

    .active {
      font-weight: 600;
      font-size: .813rem;
      line-height: 1.25rem;
      color: #246CB7;
      border-bottom: .125rem solid #246CB7;
      padding-bottom: 1.25rem;
      margin-bottom: -0.07rem;
      margin-top: -0.15rem;
      transition: font-size .2s;
      opacity: 1;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.06rem;

    .article {
      width: calc(33.333% - 2.125rem);
      margin: 0 1.06rem 3.125rem 1.06rem;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .project {
    &__content {
      .article {
        width: calc(50% - 2.125rem);
      }
    }
  }
}
@media screen and (max-width: 440px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .project {
      &__content {
        .article {
          width: calc(100% - 2.125rem);
        }
      }
    }
    .project__tabs{
      overflow-x: scroll;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      p, a{
        flex: 0 0 auto;
      }
    }
}
</style>
